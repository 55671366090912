import form from "@/lib/form";

export default {
  profile: {
    liveScoreOptions: form.idNamePairs(['GymFed', 'FfGym', 'KNGU'], ''),
  },
  eventDiscipline: {
    liveScoreOptions: form.idNamePairs(['none', 'publicAPI', 'ScoreExpress', 'KNGU'], 'publicationMethod.'),
    clubSymbolsOptions: form.idNamePairs(['flags', 'region'], 'clubSymbol.'),
    groupDisplay: form.idNamePairs(['members', 'group'], 'groupDisplay.'),
    teamDisplay: form.idNamePairs(['team', 'club', 'club-team'], 'teamDisplay.'),
    finalsReports: form.idNamePairs(['regular', 'combined'], 'discipline.finalsReports.'),
    awardOrder: form.idNamePairs(['awardBib', 'bib', 'name', 'clubName'], "awardOrder."),
  },
  category: {
    genderOptions: form.idNamePairs(['male', 'female', 'mixed'], 'gender.'),
    participantTypeOptions: form.idNamePairs(["clubMember", "group"], 'participantType.'),
    scorePublicationOptions: form.idNamePairs(['totals', 'full', 'none'], 'scorePublication.'),
    rankingPublicationOptions: form.idNamePairs(['full', 'awards', 'none'], 'rankingPublication.'),
    teamMethodOptions: form.idNamePairs(['event', 'AA', 'exercises'], 'category.teamMethod.'),
    roundResultOptions: form.idNamePairs(['reset', 'add'], 'final_result.'),
    awardTypes: form.idNamePairs(['score', 'ranking_percentage', 'subdivision_percentage'], 'awardType.'),
  },
  session: {
    rotationTypes: form.idNamePairs(['fixed', 'alternating', 'schedule'], 'session.rotationType.'),
    timeTableItems: form.idNamePairs(
      ['judgemeeting', 'warmupStart', 'marchin', 'competition', 'ceremony', 'end'], 'session.schedule.'),
  },
  participation: {
    statusOptions: form.idNamePairs(['present', 'absent', 'reserve', 'not-selected'], 'participation.status.'),
  },
  planning: {
    groupByClub: form.idNamePairs(['mixClubs', 'groupClubs'], 'planning.groupOption.'),
    groupByCategory: form.idNamePairs(['horizontal', 'vertical'], 'planning.groupOption.'),
    blockTimeType: form.idNamePairs(['byCount', 'fixed'], 'planning.blockTime.'),
    orderPrinciples: form.idNamePairs(['random', 'repeat', 'rotate'], 'planning.orderPrinciple.'),
  },
  warmup: {
    types: form.idNamePairs(['regular','scheduled'], ''),
  }
}
