
import participantLib from 'client/lib/participant'
import sessionLib from 'client/lib/session'
import regions from "@/lib/sortathletes/regions";

import store from '@/store'

import clone from 'lodash/clone'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import sortBy from 'lodash/sortBy'

import {
  shuffleArray,
  getParticipationClub,
  removeMetaDataFromParticipations,
  getSessionBlocks,
  selectBlocks,
  getSessionStartTime,
} from '@/lib/sortathletes/common'
import moment from "moment";

/**
 * Create a randomized working order, taking into account the preferences given in the configuration, for
 * session that have a block working order structure
 *
 * @param planningConfig the configuration object containing working order preferences.
 * @param session the session for which the order must be created.
 * @param set set for which the order must be created, if 0 or omitted, the order is created over all sets.
 */
const createBlockOrder = (planningConfig, session, set = 0) => {
  return new Promise((resolve) => {
    const context = getBlockOrderOptions(planningConfig, session)
    context.checkOverlaps = planningConfig.checkOverlaps
    context.skipRotations = session.skipRotations || 0
    context.session = session

    if (session.rotationFree) {
      set = 0
    }
    console.log('createBlockOrder', session.name, set)

    const blocks = getSessionBlocks(session, set)

    let baseGroup = {}

    if (planningConfig.groupByCategory === 'horizontal' && context.groupLevels[0]?.type === 'category') {
      console.log('banded order')
      const participations = getSessionParticipations(session, set, context)
      const sections = createBandedSections(participations, session)
      context.groupLevels = context.groupLevels.slice(1)

      const baseGroups = sections.map(section => deriveBlockOrder(section, blocks, context))
      baseGroup = mergeBandedBaseGroups(baseGroups, blocks)
      removeMetaDataFromParticipations(participations)
    } else {
      if (planningConfig.groupPanels && session.rotationFree) {
        const baseGroups = []
        for (let s = 0; s < session.sets; s += 2) {
          const participations = concat(
            getSessionParticipations(session, s+1, context),
            getSessionParticipations(session, s+2, context)
          )
          const laneBlocks = blocks.filter(b => b.set === s+1 || b.set === s+2)
          console.log('derive line order', s, s+1, s+2)
          console.log(participations)
          console.log(laneBlocks)

          baseGroups.push(deriveBlockOrder(participations, laneBlocks, context))
          removeMetaDataFromParticipations(participations)
        }

        // merge baseGroups into a single baseGroup
        baseGroup = {
          blocks: [],
          participations: [],
          size: 0,
        }
        baseGroups.forEach(bg => {
          baseGroup.blocks = concat(baseGroup.blocks, bg.blocks)
          baseGroup.participations = concat(baseGroup.participations, bg.participations)
          baseGroup.size += bg.size
        })
      }
      else {
        const participations = getSessionParticipations(session, set, context)
        baseGroup = deriveBlockOrder(participations, blocks, context)
        removeMetaDataFromParticipations(participations)
      }
    }

    resolve(baseGroup)
  })
}

const createBandedSections = (participations, session) => {
  console.log(participations)
  let sections = []

  participations.forEach(participation => {
    const category = participantLib.getCategory(participation)
    let label = category.name
    const categoryRound = category.rounds.find(cr => cr.roundId === session.roundId)
    if (categoryRound.config?.group) {
      label = categoryRound.config.group
    }

    let section = sections.find(s => s.label === label)
    if (! section) {
      section = {
        label,
        index: category.index,
        participations: []
      }
      sections.push(section)
    } else {
      section.index = Math.min(section.index, category.index)
    }
    section.participations.push(participation)
  })

  sections = sortBy(sections,'index')

  return sections.map(s => s.participations)
}

const deriveBlockOrder = (participations, blocks, context) => {
  const blockQuotum = Math.ceil(participations.length / blocks.length);
  const baseGroup = {
    participations: clone(participations),
    size: participations.length,
    blocks: blocks.map((block) => createBlockQuotum(block, blockQuotum))
  }
  prepareGroups([baseGroup], context)

  return baseGroup
}

const mergeBandedBaseGroups = (baseGroups, blocks) => {
  const baseGroupBlocks = blocks.map(block => {
    let participations = []

    baseGroups.forEach(baseGroup => {
      const baseGroupBlock = baseGroup.blocks.find(bgBlock => bgBlock.block.id === block.id)
      if (baseGroupBlock) {
        participations = participations.concat(baseGroupBlock.participations)
        if (baseGroupBlock.restQuotum) {
          const fillers = Array.from(Array(baseGroupBlock.restQuotum), () => ({filler: 'filler'}))
          console.log(fillers)
          participations = participations.concat(fillers)
        }
      }
    })

    return {
      block,
      participations
    }
  })

  return {
    blocks: baseGroupBlocks
  }
}

  /**
 * Return a list of grouping levels to instruct the algorithm how to distribute participants over blocks
 *
 * Grouping level properties:
 * - type: basis for grouping participants (category, club, region)
 * - randomiseGroupOrder: if false groups are assigned to blocks in the order they have been provided by the grouping function
 *     if true, groups are assigned to random blocks
 * - mixGroupsInBlock: if true, participant order will be reshuffled in a block after block assignment in the level 1 up
 *
 * @param planningConfig planning config object with order preferences
 * @param session session for which the levels need to be obtained
 * @returns {{groupLevels: []}} ordered grouping levels
 */
const getBlockOrderOptions = (planningConfig, session) => {
  const groupLevels = []

  const secondaryType = sessionLib.getSessionRotationTypeSecondary(session)

  planningConfig.grouping.forEach((pref, pref_i) => {
    switch(pref.type) {
      case 'club-stick': {
        let maxBlocks = 1
        if (secondaryType === 'mixed') {
          maxBlocks = 2
        }
        // add club grouping level
        const mixGroups = (planningConfig.groupClubs === 'mixClubs') && ((pref_i + 1) === planningConfig.grouping.length)
        groupLevels.push({
          type: 'club',
          randomiseGroupOrder: true,
          mixGroupsInBlock: mixGroups,
          keepTogether: false,
          maxBlocks,
          blockOrder: ['set', 'index'],
        })
        break
      }
      case 'category-stick':
        if (secondaryType === 'block') break
        // add category grouping level
        groupLevels.push({
          type: 'category',
          randomiseGroupOrder: false,
          mixGroupsInBlock: false,
          keepTogether: false,
          maxBlocks: 1,
          blockOrder: ['set', 'index'],
        })
        break
      case 'region-stick':
        // add region grouping level
        groupLevels.push({
          type: 'region',
          randomiseGroupOrder: false,
          mixGroupsInBlock: true,
          keepTogether: false,
          maxBlocks: 1,
          blockOrder: ['index', 'set'],
        })
        break
      case 'team-stick':
        groupLevels.push({
          type: 'team',
          randomiseGroupOrder: true,
          mixGroupsInBlock: false,
          keepTogether: true,
          maxBlocks: 1,
          blockOrder: ['set', 'index'],
        })
    }
  })

  return { groupLevels }
}

const getSessionParticipations = (session, set, context = {}) => {
  let participations = sessionLib.getParticipations(session, set)

  // remove all participants that are not present
  participations = filter(participations, part => {
    const partRound = find(part.rounds, pRound => {
      return pRound.roundId === session.roundId
    })

    return partRound?.status === 'present';
  })

  if (context.checkOverlaps) {
    // add used rotations to participations
    const sessions = sortBy(store.state.sessions.items, ['date', 'index'])
    const sessionIndex = findIndex(sessions, s => s.id  === session.id)
    console.log(sessionIndex)
    const sessionData = store.state.clubSchedule.data.find(s => s.index === sessionIndex)
    participations.forEach(part => {
      part.usedRotations = sessionData.items.filter(i => {
        return i.participantId === part.participantId && i.eventDisciplineId !== session.eventDisciplineId
      }).map(i => i.rotation)
    })
  }

  return participations
}

/**
 * Recursive algorithm to arrange participants into blocks based on grouping levels
 *
 * @param groups participant groups determined on the previous level
 * @param context context to process in this execution
 */
const prepareGroups = (groups, context) => {
  if (! context.groupLevels.length) {
    // no grouping left to do assign participants random to blocks
    groups.forEach(group => assignGroupParticipations(group))
    return
  }

  // otherwise prepare groups on this level and next levels
  const groupLevel = context.groupLevels[0]
  const otherLevels = context.groupLevels.slice(1)
  const newContext = {
    ... context,
    groupLevels: otherLevels,
  }

  groups.forEach(group => {
    let subGroups = []
    switch (groupLevel.type) {
      case 'club':
        subGroups = createGroupsByClub(group.participations)
        break
      case 'category':
        subGroups = createGroupsByCategory(group.participations)
        break
      case 'team':
        subGroups = createGroupsByTeam(group.participations)
        break
      case 'region':
        subGroups = createGroupsByRegion(group.participations, context.session)
        break
    }

    assignGroupBlocks(subGroups, group.blocks, context, groupLevel)

    prepareGroups(subGroups, newContext)

    subGroups.forEach(subGroup => {
      mergeSubGroupBlocks(group, subGroup, context)
    })

    // randomize block orders if next level has randomizeUpperLevel flag
    if (groupLevel.mixGroupsInBlock) {
      randomiseBlocks(group)
    }
  })
}

const randomiseBlocks = (group) => {
  group.blocks.forEach(b => {
    b.participations = shuffleArray(b.participations)
  })
}

const assignGroupParticipations = (group) => {
  const participations = shuffleArray(group.participations)

  group.blocks.forEach(block => {
    block.participations = participations.splice(0, block.quotum)
  })
}

/** create a (sub)group of participants per club
 *  Participants that have the training center field filed out, are separated
 *  into groups per training center
 */
const createGroupsByClub = (participations) => {
  const groups = []

  participations.forEach((part) => {

    const club = getParticipationClub(part)

    const group = groups.find(g => g.club.id === club.id)
    if (group) {
      groupAddParticipation(group, part)
    } else {
      groups.push({
        club,
        size: 1,
        participations: [part],
      })
    }
  })

  return groups
}

/** create a (sub)group per category */
const createGroupsByCategory = (participations) => {
  const groups = []

  participations.forEach((part) => {
    const group = groups.find(g => g.category.id === part.categoryId)
    if (group) {
      groupAddParticipation(group, part)
    } else {
      const category = participantLib.getCategory(part)
      groups.push({
        category,
        size: 1,
        participations: [part],
      })
    }
  })

  return sortBy(groups, 'category.index')
}

/** create a (sub)group of participants per team */
const createGroupsByTeam = (participations) => {
  const groups = []

  participations.forEach((part) => {
    const teamPart = getTeamParticipation(part)

    const group = groups.find(g => g.teamPart.id === teamPart.id)
    if (group) {
      groupAddParticipation(group, part)
    } else {
      groups.push({
        teamPart,
        size: 1,
        participations: [part],
      })
    }
  })
  return groups
}

/**
 * group participants by region and order regions according to distance
 * @param participations
 */
const createGroupsByRegion = (participations, session) => {
  console.log('groups by region')
  const groups = []

  const event = store.state.events.current
  const regionId = event.regionId
  let regionDir = 0
  if (regionId) {
    // calculate difference between competition time and 13:00h
    const competitionTime = getSessionStartTime(session)
    if (competitionTime) {
      const refTime = moment('13:00', 'HH:mm')
      const timeDiff = refTime.diff(competitionTime, 'hours')

      if (timeDiff > 0) {
        regionDir = 1
      } else {
        regionDir = -1
      }
    }
  }

  participations.forEach((part) => {

    const club = getParticipationClub(part)

    const clubRegionId = club.regionId ? club.regionId : 'none'

    const group = groups.find(g => g.regionId === clubRegionId)
    if (group) {
      groupAddParticipation(group, part)
    } else {
      let distance = 0
      if (regionId && club.regionId) {
        distance = regions.distance(regionId, club.regionId)
      }
      groups.push({
        regionId: clubRegionId,
        distance,
        size: 1,
        participations: [part],
      })
    }
  })
  console.log(regionId, regionDir)
  console.log(sortBy(groups, g => g.distance * regionDir))
  return sortBy(groups, g => g.distance * regionDir)
}


const groupAddParticipation = (group, participation) => {
  group.participations.push(participation)
  group.size += 1
}

/**
 * Check which blocks can be used based on overlaps
 * @param group
 * @param blocks
 * @param context
 * @returns {*[]}
 */
const checkAvailableBlocks = (group, blocks, context) => {
  const blockSafe = {}
  blocks.forEach(b => {
    blockSafe[b.block.id] = safeParticipations(group.participations, b.block.index + context.skipRotations)
  })
  console.log(blockSafe)

  // check if we have conflict free blocks available to for the total capacity of the group
  let totalCapacity = 0
  let availableBlocks = blocks.filter(b => {
    const free = blockSafe[b.block.id].length === group.participations.length
    if (free) {
      totalCapacity += b.restQuotum
    }
    return free
  })

  if (totalCapacity >= group.participations.length) {
    return availableBlocks
  }

  return [...blocks]
}

/**
 * return an array with safe participations for the rotation
 * @param participations
 * @param rotation
 */
const safeParticipations = (participations, rotation) => {
  return participations.filter(part => ! part.usedRotations.includes(rotation))
}

const assignGroupBlocks = (groups, blocks, context, groupLevel) => {
  if (groupLevel.randomiseGroupOrder) {
    groups = sortBy(groups, g => -g.size)
  }

  groups.forEach(group => {
    let rest = group.size
    const groupBlocks = []

    let availableBlocks = sortBy(blocks, groupLevel.blockOrder.map(i => 'block.' + i))
    if (context.checkOverlaps) {
      availableBlocks = checkAvailableBlocks(group, blocks, context, groupLevel)
    }

    while (rest > 0) {
      // find first block with space
      const bs = selectBlocks(group, rest, availableBlocks, groupLevel)

      if (! bs.length) {
        rest = 0
        console.log('no blocks selected')
      }

      bs.forEach(b => {
        const use = b.quotum
        groupBlocks.push(createBlockQuotum(b.block.block, use))
        b.block.restQuotum -= use
        rest -= use
      })
    }

    group.blocks = groupBlocks
  })
}

const getTeamParticipation = (participation) => {
  if (participation.team) return participation.team

  let teamPart = participantLib.getTeamParticipation(participation)
  console.log(teamPart)

  if (! teamPart) {
    teamPart = {
      id: 'noTeam',
    }
  }

  participation.teamPart = teamPart
  return teamPart
}

const createBlockQuotum = (block, quotum) => {
  return {
    block: block,
    quotum: quotum,
    restQuotum: quotum,
    participations: [],
  }
}

/** add participants of the blocks in the subgroup to the blocks in the group */
const mergeSubGroupBlocks = (group, subGroup) => {
  subGroup.blocks.forEach(subBlock => {
    const block = group.blocks.find(b => b.block.id === subBlock.block.id)
    block.participations = block.participations.concat(subBlock.participations)
  })
}


// Declare exports
export {
  createBlockOrder,
  createGroupsByCategory,
}
